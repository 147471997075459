import React from "react";
import "./styles.scss";
import { Row, Col } from "react-bootstrap";
import AnimationContainer from "components/animation-container";
import BaffleText from "components/baffle-text";
import ThemeContext from "../../context";
import * as emailjs from "emailjs-com";
import Swal from "sweetalert2";

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            phone: "",
            message: "",
            error: false,
            show: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.show = this.show.bind(this);
    }

    static contextType = ThemeContext;

    show() {
        this.setState({ show: true });
    }

    check(val) {
        if (this.state.error && val === "") {
            return false;
        } else {
            return true;
        }
    }

    submit() {
        if (
            this.state.name === "" ||
            this.state.email === "" ||
            this.state.message === ""
        ) {
            this.setState({ error: true });
        } else {
            this.setState({ error: false });
        }
        this.handleSubmit();
        Swal.fire(
            "Thanks for your message",
            "I'll get back to you as soon as possible!",
            "success"
        );
        this.resetForm();
    }

    handleSubmit(e) {
        // e.preventDefault();
        const { message, name, phone, email } = this.state;
        let templateParams = {
            to_email: "alexpfeiferwebmailer@gmail.com",
            name: name,
            email: email,
            phone: phone,
            message: message,
        };
        // emailjs.send(
        //     "default_service",
        //     "template_vy5xs4j",
        //     templateParams,
        //     "GB7gztFwRQJ9lV3tx"
        // );

        emailjs
            .send(
                "service_j5p251m",
                "template_vy5xs4j",
                templateParams,
                "EMF5btL-XtZ5N6dLe"
            )
            .then(
                function (response) {
                    console.log("SUCCESS!", response.status, response.text);
                    this.resetForm();
                },
                function (error) {
                    console.log("FAILED...", error);
                    this.resetForm();
                }
            );
            this.resetForm()
    }
    resetForm() {
        this.setState({
            name: "",
            email: "",
            phone: "",
            message: "",
        });
    }
    handleChange = (param, e) => {
        this.setState({ [param]: e.target.value });
    };

    render() {
        return (
            <section
                id={`${this.props.id}`}
                className="contact"
                style={{ height: this.context.height }}>
                <Row>
                    <Col md={2} className="side">
                        <h2>
                            <BaffleText
                                text="Contact"
                                revealDuration={500}
                                revealDelay={500}
                                parentMethod={this.show}
                                callMethodTime={1100}
                            />
                        </h2>
                    </Col>
                    <Col md={5} className="form">
                        {this.form()}
                    </Col>
                    <Col md={5} className="map">
                        {this.map()}
                    </Col>
                </Row>
            </section>
        );
    }

    form() {
        if (this.state.show || this.context.height === "auto") {
            return (
                <AnimationContainer
                    delay={0}
                    animation="fadeInUp fast"
                    onSubmit={this.handleSubmit.bind(this)}>
                    <div className="form-container">
                        <div className="line-text">
                            <h4>Get In Touch</h4>
                            <AnimationContainer
                                delay={50}
                                animation="fadeInUp fast">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className={`name ${
                                            this.check(this.state.name)
                                                ? ""
                                                : "error"
                                        }`}
                                        placeholder="Name"
                                        onChange={(e) =>
                                            this.setState({
                                                name: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </AnimationContainer>
                            <AnimationContainer
                                delay={100}
                                animation="fadeInUp fast">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className={`email ${
                                            this.check(this.state.email)
                                                ? ""
                                                : "error"
                                        }`}
                                        placeholder="Email"
                                        onChange={(e) =>
                                            this.setState({
                                                email: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </AnimationContainer>
                            <AnimationContainer
                                delay={150}
                                animation="fadeInUp fast">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="phone"
                                        placeholder="Phone"
                                        onChange={(e) =>
                                            this.setState({
                                                phone: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </AnimationContainer>
                            <AnimationContainer
                                delay={200}
                                animation="fadeInUp fast">
                                <div className="form-group">
                                    <textarea
                                        className={`message ${
                                            this.check(this.state.message)
                                                ? ""
                                                : "error"
                                        }`}
                                        placeholder="Message"
                                        onChange={(e) =>
                                            this.setState({
                                                message: e.target.value,
                                            })
                                        }></textarea>
                                </div>
                            </AnimationContainer>
                            <AnimationContainer
                                delay={250}
                                animation="fadeInUp fast">
                                <div className="submit">
                                    <button
                                        className={`hover-button ${
                                            this.state.error ? "error" : ""
                                        }`}
                                        type="submit"
                                        onClick={() => this.submit()}>
                                        <span>Send Message</span>
                                    </button>
                                </div>
                            </AnimationContainer>
                        </div>
                    </div>
                </AnimationContainer>
            );
        }
    }

    map() {
        if (this.state.show || this.context.height === "auto") {
            return (
                <AnimationContainer
                    delay={1000}
                    animation="fadeIn fast"
                    height={this.context.height}>
                    <iframe
                        title="map"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        loading="lazy"
                        src="https://www.google.com/maps/embed/v1/view?zoom=13&center=48.4284%2C-123.3656&key=AIzaSyANGKiUtY8QmXFSnZpCnpf3ACpUP0XNbQc"></iframe>
                </AnimationContainer>
            );
        }
    }
}

export default Contact;
