import React from "react";
import Particles from "react-particles-js";
import Progress from "components/progress";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGithub,
    faLinkedin,
    faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import ThemeContext from "../../context";
import "./styles.scss";

class Hero extends React.Component {
    static contextType = ThemeContext;

    render() {
        return (
            <section
                id={`${this.props.id}`}
                className="about"
                style={{ height: this.context.height }}>
                {this.particles()}
                <Row>
                    <Col md={6} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>About Me</h4>
                            </div>
                            <h3>
                                I'm a Full Stack web developer working from home
                            </h3>
                            <div className="separator" />
                            <p>
                                I enjoy being challenged and engaging with
                                projects that require me to work outside my
                                comfort zone and knowledge set. My past work
                                experiences as an audio-visual technician
                                required me to continuously adapt and evolve
                                depending on client demands and unforeseen
                                situations. I assembled, managed, and
                                troubleshot incredibly large and intricate A/V
                                systems. I thoroughly enjoyed troubleshooting
                                such complex systems and this practice has
                                carried over to coding. During my time at
                                Bloomtech, I collaborated remotely with various
                                teams of developers to construct web
                                applications and solve problems. I learned to
                                follow the industry's best practices to
                                construct the most accessible, aesthetic, and
                                user-friendly websites possible. I believe in
                                continuous improvement and being curious about
                                every project you work on. I like to spend my
                                free time working on projects and further
                                developing my skills. There's nothing more
                                satisfying than solving a good bug.
                            </p>
                            <div className="social social_icons">
                                <FontAwesomeIcon
                                    icon={faGithub}
                                    className="social_icon"
                                    onClick={() =>
                                        window.open(
                                            "https://github.com/apfeif12"
                                        )
                                    }
                                />
                                <FontAwesomeIcon
                                    icon={faTwitter}
                                    className="social_icon"
                                    onClick={() =>
                                        window.open(
                                            "https://twitter.com/AlexPfeifer6"
                                        )
                                    }
                                />
                                <FontAwesomeIcon
                                    icon={faLinkedin}
                                    className="social_icon"
                                    onClick={() =>
                                        window.open(
                                            "https://www.linkedin.com/in/alex-pfeifer12/"
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="skills">
                        <div className="line-text">
                            <h4>My Skills</h4>
                        </div>
                        <div className="skills-container">
                            <Progress name="React" value={100} delay={1100} />
                            <Progress
                                name="Web Design"
                                value={90}
                                delay={1100}
                            />
                            <Progress
                                name="Javascript"
                                value={90}
                                delay={1100}
                            />
                            <Progress name="HTML5" value={90} delay={1100} />
                            <Progress name="GIT" value={90} delay={1100} />
                            <Progress
                                name="REST APIs"
                                value={80}
                                delay={1100}
                            />
                            <Progress name="Gatsby" value={60} delay={1100} />
                            <Progress name="MUI" value={60} delay={1100} />
                            <Progress name="Redux" value={50} delay={1100} />
                        </div>
                    </Col>
                </Row>
            </section>
        );
    }

    particles() {
        return (
            <Particles
                className="particles"
                params={{
                    particles: {
                        number: {
                            value: 50,
                            density: {
                                enable: false,
                                value_area: 5000,
                            },
                        },
                        line_linked: {
                            enable: true,
                            opacity: 0.5,
                        },
                        size: {
                            value: 1,
                        },
                    },
                    retina_detect: true,
                }}
            />
        );
    }
}

export default Hero;
